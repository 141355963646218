export const rails_ujs = require("@rails/ujs")
rails_ujs.start()
// require("turbolinks").start()
require("@rails/activestorage").start()
global.$ = require('jquery')
global.jQuery = global.$;

require("clipboard")

global.toastr = require("toastr")
require("app")
