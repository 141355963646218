$( document ).ready(function() {

    var clipboard = new ClipboardJS('.btn-clipboard');

    clipboard.on('success', function(e) {
        toastr.clear();
        var wallet = $(e.trigger).data('wallet');
        if(wallet) {
            var note = $(e.trigger).data('note');
            var info = "Your " + wallet + " address has been copied to clipboard!<br/><b>"+ note +"</b><br/> <button type='button' class='btn btn-danger mt-1'>I understood</button>";
            toastr.info(info, "Success", { timeOut: 0, extendedTimeOut: 0, hideDuration: 0 });
        } else {
            toastr.info('Copied!', "Success", { timeOut: 0 });
        }
       
    });

    if ($('#2fa-code').length > 0) {
        function fetchData(){
            $.ajax({
                url: '/security/totp?format=json',
                type: 'GET',
                success: function(response){
                    $('#2fa-code').html(response.totp);
                }
            });
        };
        fetchData();
        setInterval(fetchData, 10000);    
    }
    
});
